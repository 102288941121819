<template>
  <vue-final-modal
    v-model="modals.confirmDeleteLimit"
    class="modal-confirm-delete-limit"
    :clickToClose="false"
    @clickOutside="closeModal('confirmDeleteLimit')"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="scroll">
      <div class="header">
        <button-modal-close @close="closeModal('confirmDeleteLimit')"/>
        <span class="title">{{ getContent(popupsData, defaultLocalePopupsData, 'deleteCashLimit.title') }}</span>
      </div>

      <span class="description">
        {{ getContent(popupsData, defaultLocalePopupsData, 'deleteCashLimit.description') }}
      </span>

      <div class="actions">
        <button-base
          type="primary"
          size="md"
          @click="emit('confirm')"
        >
          {{ props.deleteButton }}
        </button-base>
        <button-base
          type="gray"
          size="md"
          @click="closeModal('confirmDeleteLimit')">
          {{ props.cancelButton }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { VueFinalModal } from 'vue-final-modal';

  const props = defineProps({
    deleteButton: { type: String, default: '' },
    cancelButton: { type: String, default: '' },
  });

  const emit = defineEmits(['confirm']);

  const limitsStore = useLimitsStore();
  const { closeModal } = useLimitsStore();
  const { modals } = storeToRefs(limitsStore);
  const { popupsData, defaultLocalePopupsData } = useGlobalStore();
  const { getContent } = useProjectMethods();

</script>

<style src="~/assets/styles/components/modal/confirm-delete-limit.scss" lang="scss" />

